<template>
	<div class="Activation">
    <loader v-if="loaded"></loader>
	</div>
</template>


<script>
	export default {
	  name: "src-pages-body-auth-activation-v1",
    data () {
			return {
				activationcode:'',
				uuid:'',
        loaded: null
			}
		},

		created() {

			if (this.$store.getters.getRouterQueryActivationCode) {
				this.api.user.activation(this.$store.getters.getRouterQueryActivationCode, this.$store.getters.getRouterQueryUUID)
        .then(({data}) => {
					let result = data;
					this.loaded = true;
					if(result.success) {

            if (result.visitor_id) {
              window.localStorage.setItem("visitor_id", result.visitor_id);
              this.$store.commit(this.types.SET_VISITOR_ID, result.visitor_id);
            }

						this.$store.commit(this.types.SET_MESSAGE_COMPONENT, result.data.messageComponent);
						this.$store.state.routerMap = result.data.routerMap;
						this.$store.commit(this.types.SET_MODAL_COMPONENT_MAP, result.data.modalComponents);
						this.$store.commit(this.types.SET_USER, result.data.user);
						this.$store.commit(this.types.SET_USER_PUSHER_INFO, result.data.user);

            this.EventBus.$emit("listenPusherChannelToGetInfoForUser");
						this.$store.state.user.status = 1;
            this.$toasted.global.infoToast({description: result.message});
            this.$router.push(result.data.redirect_url);
					} else {
            this.$toasted.global.errorToast({description: result.message});
            this.$router.push('/');
					}
				})
        .catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
			} else {
				this.loaded = true;
      }
		}
	}
</script>

<style scoped>
	h1, h2 {
		font-weight: normal;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #42b983;
	}
</style>
